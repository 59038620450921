/* You can add global styles to this file, and also import other style files */
body {
  background: #fff;

  .container {
    .header {
      padding: 40px 15px;
      h1, p{
        color:#002856
      }
      p{
        font-size: 25px
      }
    }
  }

  .container-fluid {
    padding: 0;
  }

  section {
    &.conferences {
      background-color: #f4f4f4;
      padding-top: 30px;

      .title {
        color: #002856;
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        margin-bottom: 4px;
      }

      .date {
        color: #000;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 4px;
      }

      .location {
        color: #000;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 4px;
      }
      .eventcode{
        margin-left:20px; font-size: 10px; color:#ccc;
      }
      .eventcode{
        margin-left:20px; font-size: 10px; color:#fff;
      }
    }
  }

  .focusbars {
    border-left: solid 15px;
    border-right: solid 15px;
    border-color: rgb(255, 84, 10);
    padding: 40px
  }

  .tlstr__left {
    max-width: 660px;
    padding: 20px 30px;
  }

  .tlstr__right {
    margin: 40px 30px 0 45px;
    width: calc(100% - 660px);
  }

  @media (max-width: 575.98px) {

    .btn {
      width: 100%
    }

    .container {
      .header {
        padding: 0 0 20px 0;

        .tlstr__right {
          display: none;
        }
      }

      .tlstr__left {
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }
}
